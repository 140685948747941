import React from 'react'
import "./Ourtwo.css"
import tm from "./tm.png"
import of from "./of.png"
function OurFour() {
  return (
    <div className='ourtwo'>
    
      <div className="second-img">
        <img src={of} alt="" height={400} />
      </div>
      <div className="second-text">
    <h1>Heroic Training Programs</h1>
    <ul>
    <li> <img src={tm} alt="" />  <strong>Quests of Movement:</strong> Each child embarks on a quest with personalized movement assessments guiding their journey.</li>
    <li><img src={tm} alt="" />  <strong>Alchemy of Nutrition:</strong>Interactive workshops reveal the secrets of nutrition, transforming everyday meals into sources of power and vitality.</li>
      <li><img src={tm} alt="" />  <strong>Enhancing Abilities:</strong>Our diverse array of exercises and games develops physical literacy like unlocking levels in a grand adventure.</li>
    </ul>
      </div>
    </div>
  )
}

export default OurFour
