import React from 'react'
import "./Our.css"
import ot from "./ot.png"
import tm from './tm.png'
function OurThree() {
  return (
    <div className='our'>
      <div className="our-text">
    <h2>Wizard Workshops</h2>
    <ul>
    <li> <img src={tm} alt="" />  <strong>Cauldron of Activities:</strong> Our workshops bubble with engaging, spirited activities that enchant children while educating them in the art of movement.</li>
    <li><img src={tm} alt="" />  <strong>Tailored Scrolls:</strong>Customized workshops ensure that every child, no matter their age or stage, finds their magic.</li>
      <li><img src={tm} alt="" />  <strong>Family Spells:</strong>We invite parents to partake in the magic, learning to sprinkle movement and joy into their daily lives.</li>
    </ul>
      </div>
      <div className="our-img">
        <img src={ot} alt="" height={400}/>
      </div>
    </div>
  )
}

export default OurThree
