import m1 from "./img/m1.png"
import m2 from "./img/m2.png"
import m3 from "./img/m3.png"
import m4 from "./img/m4.png"
export const Data=[
{
img: m1,
heading:"Epic Fitness Adventures" ,
text1: "Witness transformations in strength, flexibility, and endurance as children conquer new peaks of fitness.",

},
{
img: m2,
heading:"Potions for Better Health" ,
text1: "A balanced blend of movement and nutrition brews improvements in health, happiness, and energy.",

},

]