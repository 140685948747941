import React from 'react'
import "./Followus.css"
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare, AiFillYoutube } from 'react-icons/ai'
const Followus = () => {
  return (
    <div className='Followus'>
      <h2>Follow Us</h2>
      <p>Stay Connected for Tips and Updates</p>
      <div className="socials">
     <a target="_blank" rel="noreferrer" href="https://www.youtube.com/@rishi9566">
      <AiFillYoutube style={{color:"#FD8C69"}}/>   </a>  
       <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/xrishi/">
        <AiFillLinkedin style={{color:"#FD8C69"}}/> </a>
      <a target="_blank" rel="noreferrer" href="https://www.instagram.com/x.rishi/?igshid=YmMyMTA2M2Y%3D&fbclid=IwAR0hHCrnwgAgLkIWdZjD3cJdNhqG-GVWARl9zeLIhSzKnYL3hG6kPwePlBk">
         <AiFillInstagram style={{color:"#FD8C69"}}/> </a>
      <a target="_blank" rel="noreferrer" href="https://twitter.com/rishi_xpl">
         <AiFillTwitterSquare style={{color:"#FD8C69"}}/> </a>
      <a target="_blank" rel="noreferrer" href="https://www.facebook.com/rishikesh.kumar.75054">
         <AiFillFacebook style={{color:"#FD8C69"}}/> </a>
    </div>
    </div>
  )
}

export default Followus
