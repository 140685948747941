import m1 from "./img/m1.png"
import m2 from "./img/m2.png"
import m3 from "./img/m3.png"

export const Data=[
{
img: m1,
heading:"Guardians of Growth" ,
text1: "Our dedicated team of movement maestros and nutrition wizards are passionate about guiding young adventurers.",

},
{
img: m2,
heading:"Circle of Support" ,
text1: "Our community extends beyond the training ground, offering spells of support and resources for ongoing growth.",

},
{
img: m3,
heading:"Circle of Support" ,
text1: "·Our community extends beyond the training ground, offering spells of support and resources for ongoing growth.",

},

]