import React from 'react'
import "./StandOut.css"
import Main from "./Main/Main"
import background from "./img/background.png"
function StandOut() {
  return (
    <div className='StandOut'>
<Main/>
    </div>
  )
}

export default StandOut
