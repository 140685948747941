import React from 'react'
import "./Magic.css"
import Main from "./Main/Main"
import background from "./img/background.png"
function Magic() {
  return (
    <div className='StandOut'>
<Main/>
    </div>
  )
}

export default Magic
