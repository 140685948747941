import Associations from "./Associations/Associations";
import Believers from "./Believers/Believers";
import News from "./News/News";
import Reviews from "./Reviews/Reviews";
import "./Home.css"
import Our from "./Our/Our";
import Ourtwo from "./Our/Ourtwo";
import Banner from "./Banner/Banner";
import line from "./img/line.png"
import StandOut from "./StandOut/StandOut"
import JoinUs from "./JoinUs/JoinUs";
import OurFour from "./Our/OurFour"
import OurThree from "./Our/OurThree";
import Magic from "./Magic/Magic";
import Followus from "./Followus/Followus";

const Home =()=>{
    return(
        <div className="Home">
    <Banner/>
    <div className="pros" 
    style={{backgroundImage: `url(${line})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    }}>
    <Our/>
    <Magic/>
    <h1 style={{padding:"2rem 0"}}>Discover Our Magical Services</h1>
    <Ourtwo/>
    </div>
    <OurThree/>
    <OurFour/>
    <StandOut/>
    <JoinUs/>
    <Followus/>
    <Reviews/>
    <Associations/>
    <News/>
    <Believers/>
        </div>
    )
}
export default Home;