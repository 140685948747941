import React from 'react'
import "./Our.css"
import pro from "./pro.png"
import { Link } from 'react-router-dom'
function Our() {
  return (
    <div className='our'>
      <div className="our-text">
        <p> At <strong className='func'>FUNc</strong> School Of Movement, we transform the way children view physical activity. Our programs are a playground for innovation in movement and nutrition, crafted to build confidence, health, and joy in every step, jump, and sprint.</p>
        <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE"> 
        <button>Know more</button>
        </a>
      </div>
      <div className="our-img">
        <img src={pro} alt="" height={400}/>
      </div>
    </div>
  )
}

export default Our
