import React from 'react'
import "./JoinUs.css"
import j1 from "./j1.png"
import { BsArrowRight } from 'react-icons/bs'
const JoinUs = () => {
  return (
    <div className='join-us-area'>
      <div className="text-area-join">
        {/* <strong>Join the Magic!</strong> */}
        <h2>Join the Magic!</h2>
        <p>Embark on a journey where fitness and fun cast spells of laughter and health. Enroll your young wizard in our programs and watch them soar to new heights of happiness and health!</p>
     <div className="j-btn">
      <span style={{fontWeight:"600", paddingBottom:"1rem"}}>Are you ready to cast a spell of health and joy? Enroll now in our next magical session or learn more about our enchanted offerings!</span>
        <span>Get Started Today!</span>
       <a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE">
        <button>Know more <BsArrowRight/></button>
        </a>
     </div>
      </div>
      <div className="img-area-join">
        <img src={j1} height={400} alt="" />
      </div>
    </div>
  )
}

export default JoinUs
