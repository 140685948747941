import React from 'react'
import "./Ourtwo.css"
import tm from "./tm.png"
import second from "./second.png"
function Ourtwo() {
  return (
    <div className='ourtwo'>
    
      <div className="second-img">
        <img src={second} alt="" height={400} />
      </div>
      <div className="second-text">
    <h1>Enchanted School Curriculum</h1>
    <ul>
    <li> <img src={tm} alt="" />  <strong>Spellbinding Integration:</strong> We weave movement magic into daily school life, making every day an adventure in active learning.</li>
    <li><img src={tm} alt="" />  <strong>Skill Potions:</strong>Crafted for balance, coordination, and agility, our curriculum develops skills as if by magic.</li>
      <li><img src={tm} alt="" />  <strong>Wizards Training for Teachers:</strong> We empower educators with mystical tools to bring movement magic into classrooms.</li>
    </ul>




    {/* <h3>Tailored Fitness Just for You
</h3> */}
{/* <p>Unlock your potential with Personal Training. Our experts begin with detailed movement and nutrition assessments to craft a fully personalized fitness plan tailored to your goals.
</p> */}
{/* <button>Know more</button> */}
      </div>
    </div>
  )
}

export default Ourtwo
